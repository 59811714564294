import { Globe } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCategoriesFilterBar } from '@lib/components/SwCategoriesFilterBar';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { HubspotForm } from '@lib/components/SwFormBuilder/HubspotForm';
import { usePublicIp } from '@lib/components/SwFormBuilder/ip';
import SwMediaCard from '@lib/components/SwMediaCard';
import { SwPageHero } from '@lib/components/SwPageHero';
import { SwSection } from '@lib/components/SwSection';
import { grey3, grey5 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwTag from '@lib/components/bedrock/badges/SwTag';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SUB_NAV_HEIGHT, SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { AspectRatios } from '@lib/enums/apectRatios';
import FlashBanner from '@app/common/components/FlashBanner';
import ReadyToGetYourCarbonOnTrackSection from '@app/common/components/ReadyToGetYourCarbonOnTrackSection';
import Seo from '@app/common/components/Seo';
import { boldFontFamily } from '../BaseLayout';
import SwLayout from '../components/v1/SwLayout';
import SwPagination from '../components/v1/pagination/SwPagination';
import SwBreadcrumb from '../components/v1/website/SwBreadcrumb';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';

const Header = styled('div')`
    padding-block-start: 64px;
    padding-block-end: 64px;
`;

const Title = styled(SwTypography)`
    max-inline-size: 330px;
`;

const FORM_ID = 'partner-form';

const PartnersPage = ({ data, ...props }) => {
    const { sections, title, subtitle, hero, subNavigation } = data.page;

    const { search } = useLocation();
    const partners = data.allGraphCmsPartner.nodes;
    const { currentPage, pageCount } = data.allGraphCmsPartner.pageInfo;
    const { type, basePath } = props.pageContext;
    const { hash } = useLocation();
    const { t } = useTranslation();
    const ip = usePublicIp();

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav activePage={WEBSITE_MENU_ITEM.resources.key} latestUpdates={data.latestArticles.nodes} />
            <SwLayout background={colors.surface.background}>
                <SwBreadcrumb basePage={'Resources'} page={WEBSITE_MENU_ITEM.partners.text} />
                <SwContainer>
                    <Header>
                        <SwTypography color={colors.common.black} component={'h1'} variant={'h3'}>
                            {title}
                        </SwTypography>
                        <SwTypography color={colors.text.secondary} component={'p'} variant={'h3'}>
                            {subtitle}
                        </SwTypography>
                    </Header>
                </SwContainer>
                <SwPageHero bgcolor={colors.grey['300']} image={hero} />
                <SwSubNav gap={32}>
                    {subNavigation.map(({ text, target, id }) => (
                        <SwSubNav.Item
                            key={id}
                            replace={false}
                            selected={hash === `#${target}`}
                            to={`${search}#${target}`}
                        >
                            {text}
                        </SwSubNav.Item>
                    ))}
                </SwSubNav>
                <SwContainer>
                    <SwSection target={sections[0].target}>
                        <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlock: 32 }}>
                            <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32}>
                                <Title component={'h2'} variant={'h3'}>
                                    {t('partners_page.title_section_1')}
                                </Title>
                                <SwFlexBox alignItems={'center'} gap={8}>
                                    <SwInternalLink
                                        replace={false}
                                        size={'large'}
                                        to={`${search}#${FORM_ID}`}
                                        withI18n={false}
                                    >
                                        {t('partners_page.become_a_partner')}
                                    </SwInternalLink>
                                </SwFlexBox>
                            </SwFlexBox>
                            <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                {t('partners_page.title_subtitle_1')}
                            </SwTypography>
                        </SwGrid>

                        <SwLine color={grey5} direction={'horizontal'} spacing={0} />
                        <SwCmsRenderer content={sections[0].children} />
                    </SwSection>
                </SwContainer>
                <SwSection mt={4} target={subNavigation[1].target}>
                    <SwSubNav position={'relative'}>
                        <SwCategoriesFilterBar
                            activeCategory={type}
                            allCategoryLabel={t('all_types')}
                            allCategoryLink={WEBSITE_MENU_ITEM.partners.link}
                            group={data.partnersByType.group}
                        />
                    </SwSubNav>
                    <SwContainer>
                        <SwGrid columns={{ xs: 1, sm: 2, md: 3 }}>
                            {partners.map((partner) => (
                                <SwMediaCard
                                    aspectRatio={AspectRatios.widescreen}
                                    background={'white'}
                                    layout={'content-bottom'}
                                    media={
                                        <GatsbyImage
                                            alt={partner.company.logo.alt}
                                            image={partner.company.logo.gatsbyImageData}
                                            objectFit={'contain'}
                                            style={{ width: '60%' }}
                                        />
                                    }
                                    mediaContainerStyle={{ display: 'grid', placeItems: 'center' }}
                                >
                                    <Stack alignItems={'start'} spacing={2}>
                                        <SwTypography variant={'headingStrong'}>{partner.company.name}</SwTypography>
                                        <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                                            {partner.types.map((type) => (
                                                <SwTag color={type.color} key={type.id} size={'small'} uppercase={true}>
                                                    {type.label}
                                                </SwTag>
                                            ))}
                                        </Stack>
                                        <SwTypography color={colors.text.secondary} variant={'body2'}>
                                            {partner.bio}
                                        </SwTypography>
                                        {partner.company.website && (
                                            <SwInternalLink
                                                href={partner.company.website}
                                                rel={'nooponer'}
                                                size={'small'}
                                                style={{
                                                    marginLeft: -8,
                                                    fontFamily: boldFontFamily,
                                                    color: grey3
                                                }}
                                                target={'_blank'}
                                                variant={'link'}
                                            >
                                                <Globe /> {t('website')}
                                            </SwInternalLink>
                                        )}
                                    </Stack>
                                </SwMediaCard>
                            ))}
                        </SwGrid>
                        {pageCount > 1 && (
                            <>
                                <SwLine color={grey5} direction={'horizontal'} spacing={2} />
                                <SwPagination
                                    basePath={basePath}
                                    currentPage={currentPage ?? 1}
                                    pageCount={pageCount}
                                    target={`#${subNavigation[1].target}`}
                                />
                            </>
                        )}
                    </SwContainer>
                </SwSection>
                <SwLine color={grey5} direction={'horizontal'} spacing={4} />
                <SwContainer>
                    <span
                        aria-hidden={'true'}
                        id={FORM_ID}
                        style={{ transform: `translateY(${-32 - SUB_NAV_HEIGHT + 1}px)`, display: 'inline-block' }}
                    />
                    <SwGrid columns={{ xs: ['1fr'], md: ['333px', '1fr'] }} gap={{ xs: 32, md: 64 }}>
                        <Stack maxWidth={333} spacing={1}>
                            <SwTypography component={'h2'} variant={'h4'}>
                                {t('partners_page.become_a_partner')}
                            </SwTypography>
                            <SwTypography color={colors.text.secondary} variant={'body2'}>
                                {t('partners_page.section_form_text')}
                            </SwTypography>
                        </Stack>

                        <SwPaper>
                            <HubspotForm form={data.form} ipAddress={ip} options={{ showSuccess: true }} />
                        </SwPaper>
                    </SwGrid>
                </SwContainer>
                <SwLine color={grey5} direction={'horizontal'} spacing={4} />
                <SwContainer>
                    <ReadyToGetYourCarbonOnTrackSection
                        noAction={true}
                        title={'partners_page.assessment_promo_title'}
                    />
                </SwContainer>

                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    pageContext,
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={pageContext.noIndex ?? seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getPartnersPageInformation(
        $limit: Int
        $skip: Int
        $type: String
        $locale: GraphCMS_Locale
        $id: String
        $formName: String = "Partner form EN"
    ) {
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        form: hubspotForm(name: { eq: $formName }) {
            ...Essentials_HubspotForm
        }
        allGraphCmsPartner(
            limit: $limit
            skip: $skip
            filter: { locale: { eq: $locale }, types: { elemMatch: { label: { eq: $type } } } }
            sort: { order: ASC, fields: rank }
        ) {
            nodes {
                id
                company {
                    ...Essentials_GraphCMS_Company
                    website
                }
                types {
                    id
                    label
                    color
                }
                bio
            }
            pageInfo {
                currentPage
                pageCount
                perPage
                totalCount
            }
        }
        partnersByType: allGraphCmsPartner(filter: { locale: { eq: $locale } }) {
            totalCount
            group(field: types___label, limit: 9) {
                nodes {
                    id
                }
                fieldValue
            }
        }
        enPage: graphCmsPage(slug: { eq: "partners" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
            id
            remoteId
            slug
            title
            subtitle
            seo {
                ...Essentials_GraphCMS_Seo
            }
            hero {
                alt
                gatsbyImageData(aspectRatio: 1.678, layout: FULL_WIDTH, placeholder: BLURRED)
            }
            subNavigation {
                ...Essentials_GraphCMS_NavTab
            }
            sections {
                ... on GraphCMS_Section {
                    id
                    __typename
                    target
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_EmphasizedText
                        ...Essentials_GraphCMS_MediaCard
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_CallToAction
                                        ...Essentials_GraphCMS_FeatureCard
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default PartnersPage;
