import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

export type SwPageHeroProps = Omit<BoxProps, 'bgcolor'> & {
    image: {
        alt: string;
        gatsbyImageData: GatsbyImageProps['image'];
    };
    bgcolor: string;
};

const HeroContainer = styled('div')<{ $bgcolor: string }>`
    --shadow-color: 0 0% 51%;
    --shadow-strength: 20%;

    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: ${({ $bgcolor }) => $bgcolor};

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        box-shadow:
            inset 0px 11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%)),
            inset 0px -11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
    }
`;

export const SwPageHero: React.FC<SwPageHeroProps> = (props) => {
    const { image, bgcolor = 'transparent', ...rest } = props;

    return (
        <Box height={575} position={'relative'} {...rest}>
            <HeroContainer $bgcolor={bgcolor}>
                <GatsbyImage alt={image.alt} image={image.gatsbyImageData} style={{ height: '100%', width: '100%' }} />
            </HeroContainer>
        </Box>
    );
};
