import React from 'react';
import { styled } from '@mui/material';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { Categories } from '@lib/enums/Categories';
import { slugifyName } from '@lib/helpers/object';

export type SwCategoriesFilterBarProps = {
    activeCategory?: Categories;
    allCategoryLink: string;
    allCategoryLabel?: string;
    group: { nodes: { id: string }[]; fieldValue: string }[];
    lastRecordId?: string;
};

const Category = styled('li')`
    scroll-snap-align: start;
    list-style: none;
`;

export const SwCategoriesFilterBar: React.FC<SwCategoriesFilterBarProps> = ({
    activeCategory,
    group,
    lastRecordId,
    allCategoryLink,
    allCategoryLabel = 'All Categories'
}) => {
    const categories =
        group
            ?.filter((category) =>
                lastRecordId
                    ? !(category.nodes.length === 1 && category.nodes.some(({ id }) => id === lastRecordId))
                    : true
            )
            .map(({ fieldValue }) => fieldValue) ?? [];

    return (
        <>
            <Category>
                <SwInternalLink
                    active={!activeCategory}
                    state={{
                        disableScrollUpdate: true
                    }}
                    to={allCategoryLink}
                    variant={'secondary'}
                >
                    {allCategoryLabel}
                </SwInternalLink>
            </Category>
            {categories.map((category) => (
                <Category key={slugifyName(category)}>
                    <SwInternalLink
                        active={activeCategory === category}
                        state={{
                            disableScrollUpdate: true
                        }}
                        to={`${allCategoryLink}/tag/${slugifyName(category)}`}
                        variant={'secondary'}
                    >
                        {category}
                    </SwInternalLink>
                </Category>
            ))}
        </>
    );
};
